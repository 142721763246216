.manage-plans-wrapper {
    margin: 2%;
}

.add-plan-btn {
    /* position: absolute;
    right: 24px;
    bottom: 24px; */

    position: relative;
    margin-left: 20px;
    margin-top: 40px;
    margin-bottom: 40px;
    float: right;
}

.add-plan-btn:hover {
    cursor: pointer;
}

.dynamic-delete-btn {
    margin-left: 2%;
}
