.manage-plans-wrapper {
    margin: 2%;
}

.add-plan-btn {
    /* position: absolute;
    right: 24px;
    bottom: 24px; */
}

.add-plan-btn:hover {
    cursor: pointer;
}

.dynamic-delete-btn {
    margin-left: 2%;
}