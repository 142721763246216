.info-card {
    margin: 5%;
}

.info-card > .ant-card-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
}

.info-card-metric {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.upcoming-card {
    margin: 1.5%;
}
