.manage-orders-wrapper {
    margin: 2%;
}

.anticon-close-circle {
    color: red;
}

.anticon-check-circle {
    color: green;
}
